import * as React from "react"

export function PlanGitProvidersFeaturesIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4222_49170"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="21"
        height="21"
      >
        <circle cx="12.4961" cy="12.9922" r="10" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_4222_49170)">
        <circle
          cx="2.49609"
          cy="23.9297"
          r="10.9375"
          stroke="black"
          strokeWidth="1.875"
        />
        <circle
          cx="2.49634"
          cy="23.9292"
          r="7.0625"
          stroke="black"
          strokeWidth="1.875"
        />
        <circle
          cx="8.43359"
          cy="-0.445312"
          r="10.9375"
          stroke="black"
          strokeWidth="1.875"
        />
        <circle
          cx="8.43384"
          cy="-0.445801"
          r="7.0625"
          stroke="black"
          strokeWidth="1.875"
        />
        <circle
          r="10.9375"
          transform="matrix(-1 0 0 1 26.5586 17.3672)"
          stroke="black"
          strokeWidth="1.875"
        />
        <circle
          r="7.0625"
          transform="matrix(-1 0 0 1 26.5583 17.3667)"
          stroke="black"
          strokeWidth="1.875"
        />
      </g>
    </svg>
  )
}
