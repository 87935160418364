import * as React from "react"

export function PlanBuildsFeaturesIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4222_49164"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="18"
        height="21"
      >
        <path
          d="M12 2.87628L19.9102 7.44326L19.9102 16.5772L12 21.1442L4.08972 16.5772L4.08972 7.44327L12 2.87628Z"
          fill="#232129"
          stroke="url(#paint0_linear_4222_49164)"
          strokeWidth="1.5"
        />
      </mask>
      <g mask="url(#mask0_4222_49164)">
        <path
          d="M8.04485 5.15977L12 2.87628L19.9102 7.44326L19.9102 16.5772L12 21.1442L4.08972 16.5772L4.08972 7.44327L8.04485 5.15977Z"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          d="M3.34253 7.01086L11.7481 11.8664C11.9028 11.9558 12.0935 11.9558 12.2482 11.8665L20.6592 7.01025"
          stroke="black"
          strokeWidth="1.5"
        />
        <circle
          r="10"
          transform="matrix(-1 7.74287e-08 7.74287e-08 1 12.0074 22.1006)"
          stroke="black"
          strokeWidth="1.5"
        />
        <ellipse
          rx="6.66667"
          ry="6.66667"
          transform="matrix(-1 7.74287e-08 7.74287e-08 1 12.0076 22.0998)"
          stroke="black"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4222_49164"
          x1="3.33972"
          y1="22.0103"
          x2="12"
          y2="22.0103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E8DE6" />
          <stop offset="1" stopColor="#B17ACC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
