/* eslint-disable no-template-curly-in-string */
module.exports = {
  planInformation: {
    morphemes: {
      tier1PlanName: "Free",
      tier2PlanName: "Individual",
      tier3PlanName: "Team",
      tier4PlanName: "Enterprise",
    },
    headers: {
      currentPlan: "Current Plan",
      planFeatureCategoryFeature: "Features",
      planFeatureCategoryCollaboration: "Collaboration",
      planFeatureCategoryLogin: "Log in with...",
      planFeatureCategoryProviders: "Git Providers",
      planFeatureCategoryDeploy: "Deploy to...",
    },
    actions: {
      pickTier1: "Get started for free",
      pickTier2: "Start 14-day free trial",
      pickTier2Aria: "Start 14-day free trial of Individual plan",
      pickTier3: "Start 14-day free trial",
      pickTier3Aria: "Start 14-day free trial of Team plan",
      pickTier4: "Contact Sales",
      changePlan: "Change plan",
    },
    messages: {
      defaultFeaturesIntro:
        "Everything in <strong>{prevTierPlanName}</strong>, plus:",
      tier1PlanDescription:
        "For <strong>personal</strong> and single purpose sites",
      tier1PlanFeatures: [
        "100 Real-Time Edits/month",
        "Standard Builds",
        "Deploy Previews",
        "Real-time CMS Previews",
        "Lighthouse Reports",
        "1 editor",
      ],
      tier2PlanDescription:
        "For <strong>pro developers</strong> building pilot projects or small sites",
      tier2PlanFeatures: [
        "250 Real-Time Edits/month",
        "Incremental Builds",
        "Deploy Previews",
        "Real-time CMS Previews",
        "Site Access Control",
        "Lighthouse Reports",
        "1 editor",
      ],
      tier3PlanDescription:
        "For <strong>organization-wide</strong> use and teams producing large sites",
      tier3PlanFeatures: [
        "1000 Real-Time Edits/month",
        "Incremental Builds",
        "Deploy Previews",
        "Real-time CMS Previews",
        "Site Access Control",
        "Lighthouse Reports",
        "5 editors",
      ],
      tier4PlanDescription:
        "<strong>Custom packages</strong> designed for the needs of your company",
      tier4PlanFeatures: [
        "Dedicated Slack Channel",
        "New Team Training",
        "Site Optimization Consultation",
        "Custom Number of Editors",
      ],
      monthlyAmountAria: "${amount} per month",
      trialNoCardRequiredHint:
        "No credit card required. After the {trialDays} days, you will automatically be downgraded to the {planName} Plan.",
      custom: "Custom",
      byQuote: "By quote",
      featureComparisonSummary:
        "A comparison of features available in the different Gatsby Cloud plans.",
      planFeatureRte: "Real-time edits",
      planFeatureContentPreviews: "CMS Previews",
      planFeatureDeployPreviews: "Deploy Previews",
      planFeatureLighthouseReports: "Lighthouse Reports",
      planFeatureIncrementalBuilds: "Incremental Builds",
      planFeatureConcurrentBuilds: "Concurrent Builds",
      planFeatureSupportSLA: "Support SLA",
      planFeatureEditors: "Editors",
      planFeatureViewers: "Viewers (coming soon)",
      planFeaturePrivateUrls: "Site Access Control",
      planFeatureCustomUrls: "Custom URLs (coming soon)",
      planFeatureEnforceSSO: "Enforce Single Sign-On",
      planFeatureGithub: "GitHub",
      planFeatureGitlab: "GitLab",
      planFeatureBitbucket: "Bitbucket (coming soon)",
      planFeatureGoogle: "Google (coming soon)",
      planFeatureGithubEnterprise: "GitHub Enterprise (coming soon)",
      planFeatureGitlabEnterprise: "GitLab Enterprise (coming soon)",
      planFeatureCloudflare: "Cloudflare",
      planFeatureNetlify: "Netlify",
      planFeatureFastly: "Fastly",
      planFeatureFirebase: "Firebase",
      planFeatureAws: "AWS S3",
      planFeatureGoogleStorage: "Google Storage",
      planFeatureZeit: "Vercel",
      planFeatureAzure: "Azure",
      planRteIntervalsFree: "100 / month",
      planRteIntervalsTeam: "250 / month",
      planRteIntervalsEnterprise: "1000 / month",
      settingsTrialEndInformation:
        "After your {trialPlanName} plan trial ends, you will automatically be converted to the {freePlanName} plan. You can <link>upgrade anytime</link> to use features found in our {trialPlanName}+ plans.",
      changePlanTrialEndInformation:
        "You will automatically be switched to the {freePlanName} plan at the end of your trial if you choose not to upgrade.",
    },
    labels: {
      linkToPricingDocs: "Plan information docs",
    },
  },
  billing: {
    addPayment: "Add a payment method",
    creditCardInformation: "Credit card information",
    name: "Name on card",
    cardNumber: "Card number",
    expirationDate: "Expiration date",
    cvcCode: "CVC code",
    optionalInformation: "Optional information",
    companyName: "Company name",
    requiredField: "This field is required",
    validEmail: "Please enter a valid email address",
    cancel: "Cancel",
    startSubscription: "Start subscription",
    subscriptionNote: 'By clicking "Start subscription", you agree with our ',
    termsAndConditions: " Terms and Conditions",
    yourOrder: "Your order",
    gatsbyPreview: "Gatsby Preview",
    total: "Total",
    paymentDetails: "Payment Details",
    updatePaymentDetails: "Update Payment Details",
    billed: "Billed",
    billedDetails: "via debit/credit card.",
    updatePayment: "Update payment information",
    contactSupport: "Contact Support",
    gotIt: "Got it!",
    plan: "Plan.",
    month: "month",
    year: "year",
    monthYear: "month / year",
    formEmail: "Email address",
    company: "Company name",
    changePlanFor: "Change plan for",
    morphemes: {
      month: "month",
    },
    headers: {
      billingAddress: "Billing address",
    },
    labels: {
      billingAddress: "Billing address",
      billingAddressLineTwo: "Apartment, Suite, etc",
      billingCity: "City",
      billingState: "State",
      billingZipCode: "Zip Code",
      billingCountry: "Country",
      selectCountry: "Select country",
      billingInterval: "Billing interval",
      billingIntervalOptionMonthly: "Monthly",
      billingIntervalOptionAnnual: "Yearly",
    },
    messages: {
      contactSalesForCustomPlan:
        "Don’t see a plan that fits your needs? We’re here to help. <link>Contact us</link> for a customized plan built for you.",
    },
  },
}
