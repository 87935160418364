import { IconProps } from "gatsby-interface"
import { PlanBuildsFeaturesIcon } from "../../assets/icons/PlanBuildsFeaturesIcon"
import { PlanPreviewsFeaturesIcon } from "../../assets/icons/PlanPreviewsFeaturesIcon"
import { PlanHostingFeaturesIcon } from "../../assets/icons/PlanHostingFeaturesIcon"
import { PlanSecurityFeaturesIcon } from "../../assets/icons/PlanSecurityFeaturesIcon"
import { PlanSupportFeaturesIcon } from "../../assets/icons/PlanSupportFeaturesIcon"
import { PlanGitProvidersFeaturesIcon } from "../../assets/icons/PlanGitProvidersFeaturesIcon"

export type Feature = {
  name: string
  id: string
  values: string[] | boolean[]
}

export type Features = {
  category: string
  items: Feature[]
  categoryIcon?: (props: IconProps) => JSX.Element
}

export const planFeatures: Features[] = [
  {
    category: ``,
    categoryIcon: undefined,
    items: [
      {
        name: `User Limits`,
        id: `userLimit`,
        values: ["1", "2 Included", "10 Included", "20+"],
      },
      {
        name: `Additional Users`,
        id: `additionalUsers`,
        values: [
          "-",
          "$20/mo per user up to 10",
          "$20/mo per user up to 20",
          "$100",
        ],
      },
    ],
  },

  {
    category: `Builds`,
    categoryIcon: PlanBuildsFeaturesIcon,
    items: [
      {
        name: `Build Concurrency`,
        id: `buildConcurrency`,
        values: ["1", "Up to 10", "Up to 10", "10+"],
      },
      {
        name: `Additional Concurrency`,
        id: `additionalConcurrency`,
        values: ["-", "$50", "$50", "$25"],
      },
      {
        name: `Number of Cores`,
        id: `numberOfCores`,
        values: ["1", "4", "4", "8"],
      },
      {
        name: `Build Memory Limit`,
        id: `buildMemoryLimit`,
        values: ["1 GB", "8 GB", "8 GB", "8+ GB"],
      },
      {
        name: `Monthly Build Minutes`,
        id: `monthlyBuildMinutes`,
        values: ["Unlimited", "Unlimited", "Unlimited", "Unlimited"],
      },
      {
        name: `Monthly Build Limits`,
        id: `monthlyBuildLimits`,
        values: ["500", "Unlimited", "Unlimited", "Unlimited"],
      },
      {
        name: `Deployments Per Day`,
        id: `deploymentsPerDay`,
        values: ["Unlimited", "Unlimited", "Unlimited", "Unlimited"],
      },
      {
        name: `Incremental Cloud Builds`,
        id: `incrementalCloudBuilds`,
        values: [false, true, true, true],
      },
      {
        name: `Lighthouse Reports`,
        id: `lighthouseReports`,
        values: [false, true, true, true],
      },
    ],
  },

  {
    category: `Previews`,
    categoryIcon: PlanPreviewsFeaturesIcon,
    items: [
      {
        name: `Deploy Previews`,
        id: `deployPreviews`,
        values: [true, true, true, true],
      },
      {
        name: `PR/Merge Previews`,
        id: `prPreviews`,
        values: [true, true, true, true],
      },
      {
        name: `CMS Previews`,
        id: `cmsPreviews`,
        values: [true, true, true, true],
      },
    ],
  },

  {
    category: `Hosting`,
    categoryIcon: PlanHostingFeaturesIcon,
    items: [
      {
        name: `Custom Domains`,
        id: `customdomains`,
        values: [`1`, `5`, `10`, `10+`],
      },
      {
        name: `Gatsby Sites`,
        id: `gatsbySites`,
        values: [`5`, `20`, `Unlimited`, `Unlimited`],
      },
      {
        name: `Bandwidth`,
        id: `bandwidth`,
        values: [`100 GB`, `Up to 1 TB`, `Up to 1.5TB`, `Custom`],
      },
      {
        name: `Requests`,
        id: `requests`,
        values: [`1M`, `Up to 10M`, `Up to 15M`, `Custom`],
      },
      {
        name: `Serverless Function Requests`,
        id: `serverlessFunctions`,
        values: [`100,000`, `250,000`, `500,000`, `Custom`],
      },
      {
        name: `Points-of-Presence(POPS)`,
        id: `pointsOfPresence`,
        values: [`33 (US + EU)`, `33 (US + EU)`, `33 (US + EU)`, `57 (Global)`],
      },
      {
        name: `Optimized Edge Images`,
        id: `optimizedEdgeImages`,
        values: [`100 images`, `5,000 images`, `10,000 images`, `Custom`],
      },
    ],
  },

  {
    category: `Reliability & Security`,
    categoryIcon: PlanSecurityFeaturesIcon,
    items: [
      {
        name: `Password Protected Previews`,
        id: `protectedPreviews`,
        values: [false, true, true, true],
      },
      {
        name: `99% Uptime SLA`,
        id: `uptimeSLA`,
        values: [false, true, true, true],
      },
      {
        name: `Build Retention`,
        id: `buildRetention`,
        values: [`1000`, `25,000`, `50,000`, `300,000`],
      },
      {
        name: `SOC 2`,
        id: `soc2`,
        values: [true, true, true, true],
      },
      {
        name: `SSO / SAML`,
        id: `ssoSaml`,
        values: [false, false, false, true],
      },
      {
        name: `Private IP Cluster`,
        id: `privateIpCluster`,
        values: [false, false, false, true],
      },
    ],
  },

  {
    category: `Support`,
    categoryIcon: PlanSupportFeaturesIcon,
    items: [
      {
        name: `Email Support`,
        id: `emailSupport`,
        values: [true, true, true, true],
      },
      {
        name: `Slack Support`,
        id: `slackSupport`,
        values: [false, false, false, true],
      },
      {
        name: `Dedicated Account Rep`,
        id: `dedicatedAccountRepo`,
        values: [false, false, false, true],
      },
      {
        name: `Onboarding & Migration`,
        id: `onboarding`,
        values: [false, false, false, true],
      },
      {
        name: `Hands-on Training`,
        id: `handsOnTraining`,
        values: [false, false, false, true],
      },
      {
        name: `Performance Audit`,
        id: `performanceAudit`,
        values: [false, false, false, true],
      },
    ],
  },

  {
    category: `Git Providers`,
    categoryIcon: PlanGitProvidersFeaturesIcon,
    items: [
      {
        name: `Repo Type`,
        id: `repoType`,
        values: [`Personal`, `Business`, `Business`, `Business`],
      },
      {
        name: `GitHub`,
        id: `gitHub`,
        values: [true, true, true, true],
      },
      {
        name: `GitLab`,
        id: `gitLab`,
        values: [true, true, true, true],
      },
      {
        name: `BitBucket`,
        id: `bitBucket`,
        values: [true, true, true, true],
      },
    ],
  },
]
