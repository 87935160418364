export type RichValue = {
  value: string | boolean
  tooltip?: string
  comment?: string
}

export type DetailsItem = {
  title: string
  id: string
  values: (string | boolean | RichValue)[]
  tooltip?: string
}

export type Details = {
  category: string
  items: DetailsItem[]
}

export const details: Details[] = [
  {
    category: `Core`,
    items: [
      {
        title: `Users`,
        id: `users`,
        values: [
          `1`,
          {
            value: `2`,
            tooltip: `Then $20/user up to 8 additional users`,
          },
          {
            value: `10`,
            tooltip: `Then $20/user up to 10 additional users`,
          },
          `10+`,
        ],
      },
      {
        title: `Max Site Size`,
        id: `maxSiteSize`,
        values: ["1,000 pages", "5,000 pages", "10,000 pages", "Unlimited"],
        tooltip: `Count of pages for the largest site in a workspace`,
      },
      {
        title: `Monthly Build Minutes`,
        id: `monthlyBuildMinutes`,
        values: [`1,000`, `10,000`, `25,000`, `50,000+`],
        tooltip: `Sum of all successful builds (production, pull request, and preview)`,
      },
      {
        title: `Sites`,
        id: `sites`,
        values: [`3`, `10`, `100`, `Unlimited`],
      },
      {
        title: `Concurrency`,
        id: `concurrency`,
        values: [
          `1`,
          {
            value: `3`,
            tooltip: `Then $50/concurrency up to 2 additional`,
          },
          {
            value: `5`,
            tooltip: `Then $50/concurrency up to 5 additional`,
          },
          `10+`,
        ],
      },
      {
        title: `Bandwidth`,
        id: `bandwidth`,
        values: [
          `100GB`,
          {
            value: `1TB`,
            tooltip: `Then $40/100GB bandwidth up to 1TB additional`,
          },
          {
            value: `1.5TB`,
            tooltip: `Then $40/100GB bandwidth up to 1TB additional`,
          },
          `2TB+`,
        ],
      },
      {
        title: `Memory`,
        id: `memory`,
        values: [`1GB`, `8GB`, `8GB`, `16GB+`],
      },
      {
        title: `Maximum Build Time`,
        id: `maximumBuildTime`,
        values: [`30`, `60`, `60`, `Unlimited`],
      },
    ],
  },
  {
    category: `Features`,
    items: [
      {
        title: `Enterprise Builds`,
        id: `enterpriseBuilds`,
        values: [false, false, false, true],
      },
      {
        title: `CDN Points-of-Presence`,
        id: `cDNPointsOfPresence`,
        values: [`US, EU`, `US, EU`, `US, EU`, `Global (Ent. Gold)`],
      },
      {
        title: `Dedicated IP`,
        id: `dedicatedIP`,
        values: [false, false, false, `Custom`],
      },
      {
        title: `Log Drains`,
        id: `logDrains`,
        values: [false, false, false, true],
      },
      {
        title: `On-Premises Git`,
        id: `onPremiseGit`,
        values: [false, false, false, true],
      },
      {
        title: `Single Sign-On`,
        id: `sso`,
        values: [false, false, false, true],
      },
      {
        title: `SOC 2`,
        id: `soc2`,
        values: [true, true, true, true],
      },
    ],
  },
  {
    category: `Support`,
    items: [
      {
        title: `Community`,
        id: `community`,
        values: [true, true, true, true],
      },
      {
        title: `Email Support`,
        id: `emailSupport`,
        values: [false, true, true, true],
      },
      {
        title: `Email Support SLA`,
        id: `emailSupportSla`,
        values: [false, true, true, true],
      },
      {
        title: `Priority Support`,
        id: `prioritySupport`,
        values: [false, false, false, true],
      },
      {
        title: `Extended Email Support Hours <br />(EU Hours)`,
        id: `extendedEmailSupportHours`,
        values: [
          false,
          false,
          false,
          { value: true, comment: `Enterprise Gold` },
        ],
      },
      {
        title: `Slack Support`,
        id: `slackSupport`,
        values: [
          false,
          false,
          false,
          { value: true, comment: `Enterprise Gold` },
        ],
      },
      {
        title: `Dedicated Account Rep`,
        id: `dedicatedAccountRep`,
        values: [
          false,
          false,
          false,
          { value: true, comment: `Enterprise Gold` },
        ],
      },
      {
        title: `Quarterly Business Reviews`,
        id: `quarterlyBusinessReviews`,
        values: [
          false,
          false,
          false,
          { value: true, comment: `Enterprise Gold` },
        ],
      },
    ],
  },
]
