import * as React from "react"
import { Heading, ThemeCss } from "gatsby-interface"
import { planInformation as text } from "../../locales/default.js"
import { supportedTiers } from "gatsby-theme-dashboard/src/modules/billing/shared/constants/tiers"
import {
  ColumnHeader,
  CategoryHeader,
  FeatureRow,
  columnHeaderCss,
} from "./FeaturesComparisonTable.parts"
import { debounce } from "../../components/shared/helpers/debounce"
import { MutableRefObject } from "react-color/node_modules/@types/react"
import { planFeatures } from "./features"

const tiers = [
  supportedTiers.FREE,
  supportedTiers.PROFESSIONAL,
  supportedTiers.AGENCY,
  supportedTiers.ENTERPRISE,
]

export function FeaturesComparisonTable() {
  const tableRef = React.useRef<HTMLTableElement>()
  const frameRef = React.useRef<HTMLDivElement>()

  const debouncedWindowMeasure = React.useCallback(
    () =>
      debounce(() => {
        const frame = frameRef.current
        const table = tableRef.current

        if (!table || !frame) return

        if (table.clientWidth > frame.clientWidth) {
          const tableHeight = table.clientHeight
          const maxFrameHeight = window.screen.height * 0.8

          frame.style.height = tableHeight > maxFrameHeight ? `80vh` : `auto`
          frame.style.overflowX = `scroll`
        } else {
          frame.style.overflow = `visible`
          frame.style.height = `auto`
        }
      }, 250),
    []
  )

  React.useEffect(() => {
    const measure = debouncedWindowMeasure()

    measure()

    window.addEventListener("resize", measure)

    return () => {
      window.removeEventListener("resize", measure)
    }
  }, [])

  return (
    <div css={rootCss} id={`plan-features-comparison`}>
      <Heading as="h3" css={headingCss}>
        {`Compare all plans & features`}
      </Heading>
      <div css={frameCss} ref={frameRef as MutableRefObject<HTMLDivElement>}>
        <table
          summary={text.messages.featureComparisonSummary}
          css={tableCss}
          ref={tableRef as MutableRefObject<HTMLTableElement>}
        >
          <thead>
            <tr>
              <th css={columnHeaderCss as ThemeCss} />
              {tiers.map((tier) => (
                <ColumnHeader tier={tier} key={tier.name} />
              ))}
            </tr>
          </thead>
          <tbody>
            {planFeatures.map((group, idx) => {
              const { category, categoryIcon, items = [] } = group

              return (
                <React.Fragment key={idx}>
                  {category && (
                    <CategoryHeader
                      category={category}
                      categoryIcon={categoryIcon}
                    />
                  )}

                  {items &&
                    items.map((item, idx) => (
                      <FeatureRow
                        tiers={tiers}
                        category={category}
                        key={item.name}
                        feature={item}
                      />
                    ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = (theme) => ({
  marginTop: theme.space[15],
})

const headingCss: ThemeCss = (theme) => ({
  fontSize: theme.fontSizes[6],
  marginBottom: theme.space[12],
  textAlign: `center`,
})

const frameCss: ThemeCss = (theme) => ({
  display: `flex`,
  background: theme.colors.white,
  WebkitOverflowScrolling: `touch`,
})

const tableCss: ThemeCss = (theme) => ({
  display: `inline-block`,
  minWidth: `60rem`,
  color: theme.colors.grey[90],
  tableLayout: `fixed`,
  margin: `0 auto`,
  position: `relative`,

  [theme.mediaQueries.hd]: {
    paddingLeft: theme.space[10],
    paddingRight: theme.space[5],
  },
})
