import * as React from "react"
import { ThemeCss, Button } from "gatsby-interface"
import { planInformation as text } from "../../locales/default.js"
import {
  ColumnHeader,
  CategoryHeader,
  DataRow,
  columnHeaderCss,
} from "./PlansComparison.parts"
import { details } from "./PlansComparison.data"
import { usePlanProfiles } from "gatsby-theme-dashboard/src/modules/billing/shared/constants/planProfiles"
import { BillingInterval } from "gatsby-theme-dashboard/src//modules/graphql/types"
import { MdExpandMore, MdExpandLess } from "react-icons/md"
import { PlanSelectorInterval } from "gatsby-theme-dashboard/src/modules/billing/shared/components/PlanSelectorInterval"

export type PlansComparisonProps = {
  selectedBillingInterval: BillingInterval
  onBillingIntervalChange: (val: BillingInterval) => void
}

export function PlansComparison({
  selectedBillingInterval,
  onBillingIntervalChange,
}: PlansComparisonProps) {
  const [isVisible, setIsVisible] = React.useState(false)
  const planProfiles = usePlanProfiles()

  return (
    <React.Fragment>
      <div css={btnCss}>
        <Button
          size="XL"
          onClick={() => setIsVisible(!isVisible)}
          leftIcon={isVisible ? <MdExpandLess /> : <MdExpandMore />}
        >
          {isVisible
            ? `Hide feature comparison`
            : `Show full feature comparison`}
        </Button>
      </div>

      {isVisible && (
        <div css={frameCss}>
          <table
            summary={text.messages.featureComparisonSummary}
            css={tableCss}
          >
            <thead>
              <tr>
                <th css={columnHeaderCss}>
                  <PlanSelectorInterval
                    interval={selectedBillingInterval}
                    onChange={onBillingIntervalChange}
                    variant="twoRows"
                    css={intervalCss}
                  />
                </th>
                {planProfiles.order.map((plan) => (
                  <ColumnHeader
                    plan={planProfiles.data[plan]}
                    key={plan}
                    billingInterval={selectedBillingInterval}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {details.map((data, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {data?.category && (
                      <CategoryHeader category={data.category} />
                    )}

                    {data?.items &&
                      data.items.map((item, idx) => (
                        <DataRow
                          category={data?.category}
                          key={item.title}
                          data={item}
                          plan={planProfiles.order[idx]}
                        />
                      ))}
                  </React.Fragment>
                )
              })}

              {/* creates nice end of emphesized Profesional column */}
              <tr css={emptyRowCss}>
                <th />
                <td />
                <td />
                <td colSpan={2} />
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  )
}

/* styles */

const intervalCss: ThemeCss = (theme) => ({
  display: `none`,

  [theme.mediaQueries.desktop]: {
    display: `block`,
    marginBottom: 0,
  },
})

const btnCss: ThemeCss = (theme) => ({
  display: `flex`,
  padding: `${theme.space[14]} 0`,
  justifyContent: `center`,

  button: {
    background: theme.colors.purple[10],
    color: theme.colors.purple[60],
    borderColor: theme.colors.purple[10],
    justifySelf: `center`,

    "&:hover": {
      background: theme.colors.purple[10],
    },
  },
})

const frameCss: ThemeCss = (theme) => ({
  display: `flex`,
  background: theme.colors.white,
  WebkitOverflowScrolling: `touch`,
  maxWidth: `100%`,
  overflowX: `scroll`,
  marginBottom: theme.space[15],

  [theme.mediaQueries.desktop]: {
    maxWidth: `none`,
    overflowX: `visible`,
  },
})

const tableCss: ThemeCss = (theme) => ({
  display: `inline-block`,
  minWidth: `60rem`,
  color: theme.colors.grey[90],
  tableLayout: `fixed`,
  margin: `0 auto`,
  position: `relative`,

  // highlights the Profesional plan column
  "td:nth-child(3), th:nth-child(3)": {
    background: theme.colors.purple[10],
  },

  [theme.mediaQueries.desktop]: {},
})

const emptyRowCss: ThemeCss = (theme) => ({
  "th, td": {
    borderBottom: 0,
    borderRadius: `0 0 ${theme.radii[3]} ${theme.radii[3]}`,
  },
})
