import { Global } from "@emotion/core"
import { AnonymousFeatureFlagProvider } from "@modules/featureFlags"
import { graphql, navigate } from "gatsby"
import { Heading, Link, Text } from "gatsby-interface"
import PropTypes from "prop-types"
import * as React from "react"
import { BillingInterval } from "../../components/billing/constants"
import { planFeatures } from "../../components/billing/features"
import { FeaturesComparisonTable } from "../../components/billing/FeaturesComparisonTable"
import { visuallyHiddenCss } from "../../components/shared/styles"
import {
  ZendeskFloatingWidget,
  ZendeskProvider,
} from "../../components/zendesk"
import { NewMultiPlanSelector } from "gatsby-theme-dashboard/src/modules/billing/shared/components/newMultiPlanSelector/NewMultiPlanSelector"
import { useSupportedTiers } from "gatsby-theme-dashboard/src/modules/billing/shared/constants/tiers"
import { MachinePricingTier } from "gatsby-theme-dashboard/src/modules/graphql/types"
import Layout from "../../layout"
import { FormattedMessage } from "../../locales/components/FormattedMessage"
import { billing as text } from "../../locales/default.js"
import { SegmentEventType, useTracker } from "../../utils/analytics"
import {
  ButcherBoxLogo,
  ClearlinkLogo,
  CostaCoffeeLogo,
  DraftKingsLogo,
  HugeLogo,
} from "./assets/CloudUsersLogos"
import {
  formatFaqContent,
  formatNewHeaderContent,
  formatRichContent,
} from "./helpers"
import Hero from "./Hero"
import NewFaqs from "./NewFaqs"
import PageContent from "./PageContent"
import {
  cloudGradientCss,
  newPricingHeaderCss,
  newPricingLedeCss,
} from "./styles"
import { useFlags } from "gatsby-theme-dashboard/src/modules/featureFlags"
import { PlanSelector } from "gatsby-theme-dashboard/src/modules/billing/shared/components/PlanSelector"
import { PageHeader } from "../../components/pricing/PageHeader"
import { CtaSection } from "../../components/pricing/CtaSection"
import { Faqs } from "../../components/pricing/Faqs"
import { Testimonials } from "../../components/pricing/Testimonials"
import { Concierge } from "../../components/pricing/Concierge.tsx"
import { PlansComparison } from "../../components/pricing/PlansComparison"

export { ContentfulHead as Head } from "../../head"

const multiSelectorCss = (theme) => ({
  marginTop: theme.space[5],
  marginBottom: theme.space[8],
})

const customPlanCss = (theme) => ({
  textAlign: `center`,
  color: theme.colors.grey[50],
  margin: 0,
})

const logosCss = (theme) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginTop: theme.space[10],
  marginBottom: theme.space[10],
  marginLeft: `auto`,
  marginRight: `auto`,
  maxWidth: `58rem`,

  svg: {
    transform: `scale(0.75)`,
  },

  [theme.mediaQueries.desktop]: {
    svg: {
      transform: `scale(1)`,
    },
  },
})

const PricingPage = ({ data, location }) => {
  const { flags, ready } = useFlags()
  const { trackSegment, trackAction } = useTracker()
  const { supportedTiers } = useSupportedTiers()

  const [planInfo, setPlanInfo] = React.useState({
    planId: null,
    planName: null,
    billingInterval: BillingInterval.Annual,
    hostingTier: null,
    buildsTier: null,
    tier: null,
  })

  const { contentfulPage } = data

  const { pageTitle, pageLede, faqTitle, faqSubtitle, faqs, faqCta } =
    React.useMemo(() => {
      const richContent = formatRichContent(contentfulPage.contentBlocks)
      const newHeaderPieces = formatNewHeaderContent(richContent)
      const faqPieces = formatFaqContent(richContent)

      return {
        ...newHeaderPieces,
        ...faqPieces,
      }
    }, [contentfulPage.contentBlocks])

  const moveToSignUp = () => {
    if (
      planInfo.buildsTier === MachinePricingTier.Free &&
      planInfo.hostingTier === MachinePricingTier.Free
    ) {
      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Plan Selected`,
        valueString: `Free`,
        uiSource: `Pricing Page`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event: `Plan Selected`,
        properties: {
          location: "Pricing Page",
          plan: "Free",
        },
      })

      navigate(`/dashboard/signup`)
    } else {
      const selectedBuilds = supportedTiers[planInfo.buildsTier].key
      const selectedHosting = supportedTiers[planInfo.hostingTier].key
      const selectedPlan = `${
        supportedTiers[planInfo.buildsTier].name
      } Builds - ${supportedTiers[planInfo.hostingTier].name} Hosting`

      trackAction({
        eventType: `TRACK_EVENT`,
        name: `Plan Selected`,
        valueString: `${selectedPlan} (trial)`,
        uiSource: `Pricing Page`,
      })
      trackSegment({
        type: SegmentEventType.Track,
        event: `Plan Selected`,
        properties: {
          location: "Pricing Page",
          plan: `${selectedPlan} (trial)`,
        },
      })

      navigate(
        `/dashboard/signup?selectedBuildsTier=${selectedBuilds}&selectedHostingTier=${selectedHosting}&selectedPlan=${selectedPlan}`
      )
    }
  }

  const handlePlanIntervalChange = (val) => {
    if (!val) {
      return
    }

    setPlanInfo({ ...planInfo, billingInterval: val })
  }

  const handlePlanTierChange = (val) => {
    setPlanInfo({
      ...planInfo,
      planId: val.id,
      planName: val.planName,
      tier: val.tier,
    })
  }

  if (!ready) {
    return null
  }

  if (flags.pricingFall2022) {
    return (
      <Layout pathname={location.pathname}>
        <PageContent>
          <PageContent.Positioner>
            <PageHeader />
            <PlanSelector selectedPlan={planInfo} onChange={setPlanInfo} />
            <PlansComparison
              selectedBillingInterval={planInfo?.billingInterval}
              onBillingIntervalChange={handlePlanIntervalChange}
            />
            <Testimonials />
            <Faqs data={faqs} />
            <Concierge />
            <Faqs data={faqs} variant="concierge" />
            <CtaSection />
          </PageContent.Positioner>
        </PageContent>
      </Layout>
    )
  }

  return (
    <Layout pathname={location.pathname}>
      <Global
        /* we have to override the global body style to make sticky headers on the futures tables workd */
        styles={{
          body: {
            overflow: `visible`,
          },
        }}
      />
      <PageContent css={{ marginTop: 0 }}>
        <Hero variant={`CENTER`} css={{ textAlign: `center` }}>
          <Heading
            as="h2"
            css={(theme) => ({
              textAlign: `center`,
              color: theme.colors.purple[60],
              fontSize: theme.fontSizes[3],
              letterSpacing: theme.letterSpacings.tracked,
              textTransform: `uppercase`,
            })}
          >
            Gatsby Cloud
          </Heading>
          <Hero.Heading css={newPricingHeaderCss}>
            <FormattedMessage
              message={` The best way to <strong>build, deploy & scale with Gatsby.</strong>`}
              tags={{
                strong: (content) => (
                  <strong
                    css={(theme) => ({
                      color: theme.colors.gatsby,
                      display: `block`,
                    })}
                  >
                    {content}
                  </strong>
                ),
              }}
            />
          </Hero.Heading>
        </Hero>

        <PageContent.Positioner>
          <NewMultiPlanSelector
            planInfo={planInfo}
            setPlanInfo={setPlanInfo}
            contactSalesLink="/contact-sales"
            css={multiSelectorCss}
            showButtons={true}
          />

          <Text size="L" css={customPlanCss}>
            <FormattedMessage
              message={text.messages.contactSalesForCustomPlan}
              tags={{
                link: (content) => <Link to="/contact-sales">{content}</Link>,
              }}
            />
          </Text>
          <Heading as="h2" css={visuallyHiddenCss}>
            Gatsby Cloud customers
          </Heading>
          <div css={logosCss}>
            <CostaCoffeeLogo />
            <ButcherBoxLogo />
            <ClearlinkLogo />
            <DraftKingsLogo />
            <HugeLogo />
          </div>

          <FeaturesComparisonTable />
        </PageContent.Positioner>

        {faqs && (
          <NewFaqs
            faqs={faqs}
            title={faqTitle}
            subtitle={faqSubtitle}
            cta={faqCta}
            titleAs="h2"
            subtitleAs="h3"
          />
        )}
      </PageContent>
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default function PricingPageWithFlags(props) {
  return (
    <AnonymousFeatureFlagProvider>
      <PricingPage {...props} />
    </AnonymousFeatureFlagProvider>
  )
}

export const pageQuery = graphql`
  query ContentfulPricingPage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      name
      title
      subtitle {
        subtitle
      }
      quickLinks {
        displayTitle
        url
      }
      sentenceLinks {
        sentence
        anchorText
        url
      }
      contentBlocks {
        __typename
        ... on ContentfulRichContentBlock {
          contentfulid
          title
          subtitle
          badge
          actions {
            displayTitle
            url
          }
          content {
            __typename
            ... on ContentfulMarkdownContent {
              body {
                childMarkdownRemark {
                  html
                }
                internal {
                  content
                }
              }
              contentfulid
            }
            ... on ContentfulPlan {
              id
              name
              intro {
                childMarkdownRemark {
                  html
                }
              }
              details {
                id
                tip {
                  id
                  content {
                    id
                    childMarkdownRemark {
                      id
                      html
                    }
                  }
                }
                text {
                  id
                  childMarkdownRemark {
                    id
                    html
                  }
                }
              }
              ctaLabel
            }
            ... on ContentfulFaq {
              id
              question
              category
              answer {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`
