import * as React from "react"

export function PlanHostingFeaturesIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4222_49166"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="21"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4189 2.13721H2.41895V22.1372H22.4189V16.709L19.9189 19.209L16.3834 15.6735L14.6156 13.9057L15.3479 12.138L14.6156 10.3702L16.3834 8.60243L19.9189 5.06689L22.4189 7.56689V2.13721Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_4222_49166)">
        <circle cx="12.4195" cy="12.1365" r="2.14286" fill="black" />
        <circle
          cx="12.4502"
          cy="12.1689"
          r="4.6875"
          stroke="black"
          strokeWidth="2.1875"
        />
        <circle
          cx="12.4189"
          cy="12.1372"
          r="8.28125"
          stroke="black"
          strokeWidth="2.1875"
        />
      </g>
    </svg>
  )
}
