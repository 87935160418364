import * as React from "react"

export function PlanSupportFeaturesIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12.1377"
        cy="13.3965"
        r="5.3125"
        stroke="black"
        strokeWidth="1.875"
      />
      <path
        d="M5.69238 11.0527C5.69238 9.34333 6.37144 7.70394 7.58017 6.49521C8.7889 5.28648 10.4283 4.60742 12.1377 4.60742C13.8471 4.60742 15.4865 5.28648 16.6952 6.49521C17.904 7.70394 18.583 9.34333 18.583 11.0527"
        stroke="black"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.27832 17.3027H5.69238C5.07078 17.3027 4.47464 17.0558 4.0351 16.6163C3.59556 16.1767 3.34863 15.5806 3.34863 14.959V12.6152C3.34863 11.9936 3.59556 11.3975 4.0351 10.958C4.47464 10.5184 5.07078 10.2715 5.69238 10.2715H6.27832C6.43372 10.2715 6.58276 10.3332 6.69264 10.4431C6.80253 10.553 6.86426 10.702 6.86426 10.8574V16.7168C6.86426 16.8722 6.80253 17.0212 6.69264 17.1311C6.58276 17.241 6.43372 17.3027 6.27832 17.3027Z"
        fill="white"
        stroke="black"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.583 17.3027H17.9971C17.8417 17.3027 17.6926 17.241 17.5827 17.1311C17.4729 17.0212 17.4111 16.8722 17.4111 16.7168V10.8574C17.4111 10.702 17.4729 10.553 17.5827 10.4431C17.6926 10.3332 17.8417 10.2715 17.9971 10.2715H18.583C19.2046 10.2715 19.8008 10.5184 20.2403 10.958C20.6798 11.3975 20.9268 11.9936 20.9268 12.6152V14.959C20.9268 15.5806 20.6798 16.1767 20.2403 16.6163C19.8008 17.0558 19.2046 17.3027 18.583 17.3027Z"
        fill="white"
        stroke="black"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6377 20.2715L18.2551 18.7212C18.7147 18.5242 19.0127 18.0723 19.0127 17.5722V16.5215"
        stroke="black"
        strokeWidth="1.875"
      />
      <rect
        x="9.6377"
        y="18.3965"
        width="5"
        height="3.75"
        rx="1.875"
        stroke="black"
        strokeWidth="1.875"
      />
    </svg>
  )
}
