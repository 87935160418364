/** @jsx jsx */
import { jsx, Global, css } from "@emotion/core"
import { useState } from "react"
import PropTypes from "prop-types"

import { Heading, Text, Link } from "gatsby-interface"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs"
import FaqCard from "./NewFaqCard"
import PageContent from "./PageContent"

const CATEGORY_ALL = `All`

function Faqs({
  faqs: allFaqs,
  title,
  subtitle,
  cta,
  titleAs = `h3`,
  subtitleAs = `h4`,
}) {
  const [activeTab, setActiveTab] = useState(0)

  // PricingFall2022 is an util category which let us add new entries without disturbing current Pricing page
  const faqs = allFaqs.filter(
    item => !item.category.includes(`PricingFall2022`)
  )

  if (faqs.length === 0) {
    return null
  }

  // Storing category names in an array ensures proper order with "All" being the first
  const faqCategoriesNames = [CATEGORY_ALL]
  const faqCategoriesCounts = {
    [CATEGORY_ALL]: faqs.length,
  }
  const faqsByCategory = {
    [CATEGORY_ALL]: faqs,
  }

  for (let i = 0; i < faqs.length; i++) {
    const item = faqs[i]
    const itemCategories = item.category

    itemCategories.forEach(cat => {
      if (faqsByCategory[cat]) {
        // Category already registered, adding another FAQ record
        faqsByCategory[cat].push(item)
        faqCategoriesCounts[cat] += 1
      } else {
        // Category not registered, add its name and initialize records array and count
        faqCategoriesNames.push(cat)
        faqsByCategory[cat] = [item]
        faqCategoriesCounts[cat] = 1
      }
    })
  }

  const renderFaqSection = (item, idx) => (
    <FaqCard key={`faq${idx}`}>
      <FaqCard.Question question={item.question} />
      <FaqCard.Answer answer={item.answer} />
    </FaqCard>
  )

  const showTabs = faqCategoriesNames.length > 1

  return (
    <PageContent.Positioner
      css={theme => ({
        // background: theme.colors.secondaryBackground,
        margin: `${theme.space[12]} 0 0`,
        padding: `${theme.space[15]} 0`,
      })}
    >
      <Heading
        variant="LIGHT"
        as={subtitleAs}
        css={theme => ({
          fontSize: theme.fontSizes[2],
          color: theme.colors.gatsby,
          textAlign: `center`,
        })}
      >
        {subtitle}
      </Heading>

      <Heading
        as={titleAs}
        css={theme => ({
          textAlign: `center`,
          fontSize: theme.fontSizes[6],
          marginTop: `${theme.space[4]}`,
        })}
      >
        {title}
      </Heading>

      <div
        css={theme => ({
          maxWidth: `55rem`,
          margin: `${theme.space[9]} auto`,
        })}
      >
        <TabsGlobalStyles />
        <Tabs
          index={activeTab}
          onChange={setActiveTab}
          css={theme =>
            showTabs && {
              display: `none`,
              [theme.mediaQueries.desktop]: {
                display: `block`,
              },
            }
          }
        >
          <TabList
            css={theme => ({
              display: `flex`,
              justifyContent: `center`,
              marginBottom: theme.space[8],
            })}
          >
            {faqCategoriesNames.map(category => (
              <Tab
                key={category}
                css={theme => ({
                  margin: `0 ${theme.space[7]}`,
                  padding: 0,
                  color: theme.colors.grey[70],
                  "&[data-selected]": {
                    color: theme.colors.purple[60],
                    fontWeight: theme.fontWeights.semiBold,
                  },
                })}
              >
                {category}
                <i
                  css={theme => ({
                    display: `inline-block`,
                    fontStyle: `normal`,
                    fontSize: theme.fontSizes[0],
                    width: `20px`,
                    height: `20px`,
                    color: theme.colors.grey[70],
                    backgroundColor: theme.colors.grey[20],
                    borderRadius: theme.radii[5],
                    textAlign: `center`,
                    marginLeft: theme.space[3],
                  })}
                >
                  {faqCategoriesCounts[category]}
                </i>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {faqCategoriesNames.map(category => (
              <TabPanel key={category}>
                {faqsByCategory[category].map(renderFaqSection)}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        <div
          css={theme =>
            showTabs && {
              display: `block`,
              [theme.mediaQueries.desktop]: {
                display: `none`,
              },
            }
          }
        >
          {faqsByCategory[CATEGORY_ALL].map(renderFaqSection)}
        </div>
      </div>
      {cta && (
        <div
          css={theme => ({
            marginTop: theme.space[15],
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            gap: theme.space[7],
          })}
        >
          <Heading as="h3">{cta.title}</Heading>
          {/* Hardcoding these values for now, as we don't want to update the copy in contentful yet */}
          <Text>
            We&apos;re here to help!{" "}
            <Link to={`/contact-sales`}>Contact us</Link> for a customized plan
            built for you.
          </Text>
        </div>
      )}
    </PageContent.Positioner>
  )
}

Faqs.propTypes = {
  faqs: PropTypes.array.isRequired,
}

export default Faqs

function TabsGlobalStyles() {
  return (
    <Global
      styles={css`
        /** @reach/tabs styles begin */
        :root {
          --reach-tabs: 1;
        }

        [data-reach-tabs][data-orientation="vertical"] {
          display: flex;
        }

        [data-reach-tab-list] {
          display: flex;
        }

        [data-reach-tab-list][aria-orientation="vertical"] {
          flex-direction: column;
        }

        [data-reach-tab] {
          display: inline-block;
          border: none;
          background: none;
          color: inherit;
          font: inherit;
          cursor: pointer;
          -webkit-appearance: none;
          -moz-appearance: none;
        }

        [data-reach-tab]:active {
        }

        [data-reach-tab]:disabled {
        }

        [data-reach-tab][data-selected] {
        }
        /** @reach/tabs styles end */
      `}
    />
  )
}
