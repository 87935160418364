import * as React from "react"

export function PlanSecurityFeaturesIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4222_49168"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="17"
        height="20"
      >
        <path
          d="M4.00807 4.82718C4.00806 4.3683 4.32036 3.96831 4.76554 3.85702L11.7655 2.10702C11.9248 2.06721 12.0914 2.06721 12.2506 2.10702L19.2506 3.85702C19.6958 3.96831 20.0081 4.36829 20.0081 4.82715L20.0082 10.2968C20.0082 18.1899 13.8544 21.2757 12.3411 21.9152C12.1272 22.0056 11.8905 22.0024 11.6788 21.9069C10.1721 21.2272 4.00807 17.977 4.00818 10.2968L4.00807 4.82718Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_4222_49168)">
        <path
          d="M23.5081 -1.45312L13.2581 -1.45313L3.00806 -1.45313L3.00806 23.0469L23.5081 23.0469L23.5081 -1.45312Z"
          fill="#0C0C0E"
        />
      </g>
      <path
        d="M9.83528 16.3022L7.00546 12.4389C6.72095 12.0272 6.79653 11.4665 7.17992 11.1448L7.17992 11.1448C7.56332 10.8231 8.12855 10.8461 8.48464 11.1977L11.7979 14.6554C12.3372 15.1881 12.3014 16.0692 11.7208 16.5564L11.7208 16.5564C11.1401 17.0437 10.2662 16.9258 9.83528 16.3022Z"
        fill="white"
      />
      <path
        d="M10.0672 16.547C9.4773 16.052 9.41936 15.165 9.93984 14.5975L16.5133 7.43043C16.8471 7.06647 17.4083 7.02982 17.7866 7.34727L17.7866 7.34727C18.1649 7.66471 18.2263 8.22374 17.9258 8.61568L12.0091 16.3338C11.5406 16.9449 10.657 17.0419 10.0672 16.547L10.0672 16.547Z"
        fill="white"
      />
      <path
        d="M10.2164 13.0283C10.5475 13.4257 10.7903 13.6906 11.2098 13.227C11.6292 12.7634 11.0773 14.397 11.0773 14.397L9.70862 13.9555L10.2164 13.0283Z"
        fill="white"
      />
    </svg>
  )
}
