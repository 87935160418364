import * as React from "react"
import { Heading, ThemeCss } from "gatsby-interface"
import underline from "../../assets/ornaments/pricing-page-header-underline.svg"

export function PageHeader() {
  return (
    <header css={headerCss}>
      <Heading as="h1" css={headingCss}>
        Pricing
      </Heading>
      <Heading as="h2" css={subHeadingCss}>
        The{" "}
        <span>
          obvious choice
          <img src={underline} alt="" />
        </span>{" "}
        for delivering <br /> customer-first web experiences.
      </Heading>
      <p css={msgCss}>
        Don't just take our word for it. Try Gatsby free for 14 days.
      </p>
    </header>
  )
}

/* styles */

const headerCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[4],
  justifyItems: `center`,
  textAlign: `center`,
  marginBottom: theme.space[8],
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.body,
})

const subHeadingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[6],
  letterSpacing: theme.letterSpacings.tight,

  span: {
    display: `inline-block`,
    position: `relative`,
  },

  img: {
    width: `96%`,
    height: `auto`,
    position: `absolute`,
    left: `50%`,
    bottom: 0,
    margin: 0,
    transform: `translate(-50%, 10%)`,
  },

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[7],
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[9],
  },
})

const msgCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[4],
})
