import * as React from "react"
import {
  Heading,
  Text,
  LinkButton,
  ThemeCss,
  CheckIcon,
  Tooltip,
} from "gatsby-interface"
import { MdChevronRight, MdInfoOutline } from "react-icons/md"
import illustration from "../../assets/pictures/pricing-page_concierge.svg"

export function Concierge() {
  return (
    <section css={sectionCss}>
      <header>
        <div>
          <Heading as="h3" css={headingCss}>
            Gatsby Concierge
          </Heading>
          <Text size="XL">
            Optimize your Gatsby experience, with the help from the experts.
          </Text>
          <LinkButton
            size="XL"
            to="/contact-sales/"
            css={chatCss}
            rightIcon={<MdChevronRight />}
          >
            Chat with us
          </LinkButton>
        </div>

        <img src={illustration} alt="" />
      </header>

      <ul css={featuresCss}>
        <li>
          Cloud <br /> Onboarding <CheckIcon css={checkCss} />
        </li>
        <li>
          Dedicated Gatsby <br /> Success Manager <CheckIcon css={checkCss} />
        </li>
        <li>
          Dedicated Gatsby <br /> Tech Lead <CheckIcon css={checkCss} />
        </li>
        <li>
          Pairing Sessions <br /> with a Gatsby Tech Lead <span>22 / year</span>
        </li>
        <li>
          Team training <br /> on new product features <span>3 / year</span>
        </li>
        <li>
          Quarterly <br /> Business Reviews <span>4 / year</span>
        </li>
      </ul>

      <Heading as="h4">Add-ons</Heading>

      <ul css={addonsCss}>
        <li>
          Front end performance audit{" "}
          <Tooltip
            label={`Your Gatsby Tech Lead will run a front end performance audit on up to 3 pages or templates on your site. They’ll present their findings via an audit report that showcases the issues, recommended solutions, the effort it will take to implement, and the impact to overall goals (core web vitals).`}
          >
            <span css={infoCss}>
              <MdInfoOutline />
            </span>
          </Tooltip>
        </li>
        <li>
          Build performance audit{" "}
          <Tooltip
            label={`Your Gatsby Tech Lead will audit your entire build process on Gatsby Cloud, both cold and warm builds. They’ll present their findings via an audit report that showcases the issues, recommended solutions, the effort it will take to implement, and the impact to overall goals (shorter build times).`}
          >
            <span css={infoCss}>
              <MdInfoOutline />
            </span>
          </Tooltip>
        </li>
        <li>
          Migration guidance{" "}
          <Tooltip
            label={`During a migration concierge, the customer success engineer is in lock-step with the development team to understand the scope of the migration and will provide a step-by-step guide on how to execute it. The lead customer success engineer will provide guidance and code reviews on the site conversion process ensuring that it is built and deployed “the Gatsby way.”`}
          >
            <span css={infoCss}>
              <MdInfoOutline />
            </span>
          </Tooltip>
        </li>
        <li>
          CMS Domain Expert Engineering Hours{" "}
          <Tooltip
            label={`Need help with implementing a specific feature or set of features for a CMS you are integrating with? You can have a Gatsby engineer work with your team to understand the feature or issue and help resolve it!`}
          >
            <span css={infoCss}>
              <MdInfoOutline />
            </span>
          </Tooltip>
        </li>
      </ul>
    </section>
  )
}

/* styles */

const sectionCss: ThemeCss = theme => ({
  marginTop: theme.space[15],
  marginBottom: theme.space[10],

  header: {
    display: `grid`,
    gridTemplateColumns: `1fr auto`,
    alignItems: `end`,

    img: {
      display: `none`,
    },
  },

  [theme.mediaQueries.desktop]: {
    header: {
      img: {
        margin: 0,
        display: `block`,
      },
    },
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[8],
  lineHeight: theme.lineHeights.solid,
  letterSpacing: theme.letterSpacings.tight,
  marginBottom: theme.space[5],
})

const chatCss: ThemeCss = theme => ({
  marginTop: theme.space[2],
})

const featuresCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  display: `grid`,
  marginTop: theme.space[8],
  marginBottom: theme.space[8],
  gap: theme.space[8],
  rowGap: theme.space[6],

  li: {
    padding: `${theme.space[6]} ${theme.space[8]}`,
    background: theme.colors.purple[5],
    fontWeight: theme.fontWeights.bold,
    borderRadius: theme.radii[4],
    margin: 0,
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `flex-end`,
  },

  span: {
    color: theme.colors.purple[50],
  },

  [theme.mediaQueries.tablet]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
  },

  [theme.mediaQueries.hd]: {
    gridTemplateColumns: `repeat(3, 1fr)`,
  },
})

const addonsCss: ThemeCss = theme => [
  featuresCss(theme),
  {
    li: {
      alignItems: `center`,
    },
  },
]

const checkCss: ThemeCss = theme => ({
  color: theme.colors.purple[50],
  width: theme.space[8],
  height: `auto`,
})

const infoCss: ThemeCss = theme => ({
  display: `flex`,
  marginRight: theme.space[2],
  marginLeft: theme.space[4],

  svg: {
    color: theme.colors.grey[50],
    width: theme.space[6],
    height: `auto`,
  },
})
