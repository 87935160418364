import * as React from "react"
import { interpolateMessage, InterpolationValues } from "../utils"

export type FormattedMessageRenderTag = (children: string) => React.ReactNode

type TagsProp<TTags> = [TTags] extends [never]
  ? { tags?: undefined }
  : [TTags] extends [string | number]
  ? { tags: Record<TTags, FormattedMessageRenderTag> }
  : { tags?: undefined }

export type FormattedMessageProps<TValues, TTags> = {
  message: string
} & TagsProp<TTags> &
  InterpolationValues<TValues>

/**
 * This component allows to display messages with "placeholders" values and tags replaced
 * by actual string values and React components respectively
 *
 * For example, if we had a message like this: "This is a <link>link to {cmsName} docs</link> you can visit",
 * we could replace "{cmsName}" with an actual CMS name and "<link>" with a component by passing "values" and "tags" props:
 *
 * <FormattedMessage<'cmsName', 'link'>
 *   message="This is a <link>link to docs</link> you can visit"
 *   values={{
 *     cmsName: "DatoCMS"
 *   }}
 *   tags={{
 *     link: text => (
 *       <Link to="/docs/some-doc">{text}</Link>
 *     ),
 *   }}
 * />
 *
 * This helps us avoid splitting "rich" text copies into multiple messages
 *
 * Note that FormattedMessage accepts type variables, TTags and TValues, which can be used to tell TypeScript
 * which values and tags are expected to be passed to the component ("<FormattedMessage<'cmsName', 'link'>"" in the example above).
 */
export function FormattedMessage<TValues = void, TTags = void>({
  message,
  values,
  tags,
}: FormattedMessageProps<TValues, TTags>) {
  const interpolatedString = interpolateMessage<TValues>(message, values)

  // If there are no tags provided just return the message
  if (!tags || Object.keys(tags).length === 0) {
    return <React.Fragment>{interpolatedString}</React.Fragment>
  }

  const children = []
  let prevIndex = 0

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore .matchAll is not supported in IE, so it seems TS does not provide types for it ¯\_(ツ)_/¯
  const tagMatches = interpolatedString.matchAll(/<(\w+)>(.+?)<\/\w+>/g)

  for (const match of tagMatches) {
    const { 0: substring, 1: tag, 2: textContent, index } = match

    children.push(interpolatedString.substring(prevIndex, index))

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore to be honest I have no idea how to type this, TS keeps shouting at me no matter what I do
    const renderTag: FormattedMessageRenderTag = tags[tag]

    if (renderTag) {
      children.push(renderTag(textContent))
    } else {
      children.push(substring)
    }

    prevIndex = (index ?? 0) + substring.length
  }

  children.push(interpolatedString.substring(prevIndex))

  // Have to use .createElement here to avoid warnings about missing "key" prop
  return React.createElement(React.Fragment, null, ...children)
}
