import { ThemeCss } from "../../theme"

export const toggleWrapperCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[1],
})

export const toggleLabelCss: ThemeCss = _theme => ({
  display: `inline-flex`,
  cursor: `pointer`,
})

export const toggleLabelContentCss: ThemeCss = theme => ({
  marginTop: theme.space[1],
  lineHeight: theme.lineHeights.dense,
  fontWeight: theme.fontWeights.semiBold,
  fontSize: theme.fontSizes[2],
  color: theme.colors.grey[90],
  fontFamily: theme.fonts.system,

  a: {
    verticalAlign: `middle`,
    svg: {
      color: theme.colors.grey[50],
    },

    "&:hover": {
      svg: {
        color: theme.colors.purple[40],
      },
    },
  },
})

export const toggleHintCss: ThemeCss = theme => ({
  display: `block`,
  paddingLeft: `calc(48px + ${theme.space[4]})`,
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.body,
  lineHeight: theme.lineHeights.dense,
})
