import * as React from "react"
import { Heading, Theme, ThemeCss } from "gatsby-interface"
import { MdExpandMore } from "react-icons/md"
import { keyframes } from "@emotion/core"
import * as sanitizeHTML from "sanitize-html"

export type FaqVariant = `main` | `concierge`

export type HeaderProps = {
  variant: FaqVariant
}

export function Header({ variant }: HeaderProps) {
  return (
    <Heading as="h3" css={headerCss}>
      <React.Fragment>
        {variant === `concierge`
          ? `More of your questions`
          : `Frequently asked questions`}
      </React.Fragment>
      , <em>answered.</em>
    </Heading>
  )
}

/* Header styles */

const headerCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[5],
  fontWeight: theme.fontWeights.semiBold,
  letterSpacing: theme.letterSpacings.tight,
  marginBottom: theme.space[9],

  em: {
    fontStyle: `normal`,
    color: theme.colors.purple[50],
    display: `block`,
  },
})

/* end of Header styles */

export type Faq = {
  question: string
  answer: string
  category: string[]
}

export type CategorySectionProps = {
  category: string
  faqs: Faq[]
  noHeading?: boolean
}

export function FaqList({
  category,
  faqs,
  noHeading = false,
}: CategorySectionProps) {
  return (
    <div css={listCss}>
      {!noHeading && (
        <Heading as="h4" css={categoryCss}>
          {category}
        </Heading>
      )}
      {faqs?.map((faq, idx) => (
        <FaqItem faq={faq} idx={idx} category={category} />
      ))}
    </div>
  )
}

/* FaqList styles */
const listCss: ThemeCss = theme => ({
  "&:not(:last-child)": {
    marginBottom: theme.space[9],
  },
})

const categoryCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  marginBottom: theme.space[5],
  color: theme.colors.grey[50],
})

/* end of FaqList styles */

export type FaqItemProps = {
  faq: Faq
  idx: number
  category: string
}

export function FaqItem({ faq, category, idx }: FaqItemProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const id = `faq-${category}-${idx}`

  return (
    <div css={faqCss}>
      <button
        onClick={handleClick}
        css={theme => btnCss(theme, isOpen)}
        aria-controls={id}
        aria-expanded={isOpen}
      >
        <Heading as="h5" css={questionCss}>
          {faq.question}
        </Heading>
        <MdExpandMore />
      </button>

      <div
        id={id}
        css={theme => [
          answerCss(theme),
          isOpen && {
            display: `block`,
          },
        ]}
        dangerouslySetInnerHTML={{ __html: sanitizeHTML(faq.answer) }}
      />
    </div>
  )
}

/* FaqItem styles */

const entry = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const faqCss: ThemeCss = theme => ({
  borderBottom: `1px solid ${theme.colors.standardLine}`,
  padding: `${theme.space[3]} 0`,
})

const btnCss = (theme: Theme, isOpen: boolean) => [
  {
    padding: `${theme.space[4]} 0`,
    background: `none`,
    border: 0,
    cursor: `pointer`,
    transition: `color 0.5s ease`,
    width: `100%`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `space-between`,

    "&:hover": {
      color: theme.colors.purple[50],

      svg: {
        opacity: 1,
        transform: isOpen ? `rotate(180deg) scale(1.5)` : `scale(1.5)`,
      },
    },

    svg: {
      width: theme.space[7],
      height: `auto`,
      opacity: 0.5,
      transition: `opacity 0.5s ease, transform 0.5s ease`,
      marginLeft: theme.space[4],
    },
  },
  isOpen && {
    svg: {
      transform: `rotate(180deg)`,
    },
  },
]

const questionCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[3],
  color: `inherit`,
  textAlign: `left`,
})

const answerCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  display: `none`,
  marginTop: theme.space[5],
  animation: `${entry} 0.5s forwards`,
  maxWidth: `880px`,
})

/* end of FaqItem styles */
