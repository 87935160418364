import * as React from "react"

export function PlanPreviewsFeaturesIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <g clip-path="url(#clip0_4222_49165)">
        <g clip-path="url(#clip1_4222_49165)">
          <path
            d="M12.9186 6.26758C8.75184 6.26758 5.19341 8.85932 3.75171 12.5177C5.19341 16.1762 8.75184 18.7679 12.9186 18.7679C17.0854 18.7679 20.6438 16.1762 22.0855 12.5177C20.6438 8.85932 17.0854 6.26758 12.9186 6.26758ZM12.9186 16.6845C10.6186 16.6845 8.75184 14.8178 8.75184 12.5177C8.75184 10.2177 10.6186 8.35097 12.9186 8.35097C15.2187 8.35097 17.0854 10.2177 17.0854 12.5177C17.0854 14.8178 15.2187 16.6845 12.9186 16.6845ZM12.9186 10.0177C11.5353 10.0177 10.4186 11.1344 10.4186 12.5177C10.4186 13.9011 11.5353 15.0178 12.9186 15.0178C14.302 15.0178 15.4187 13.9011 15.4187 12.5177C15.4187 11.1344 14.302 10.0177 12.9186 10.0177Z"
            fill="black"
          />
        </g>
        <circle
          cx="12.9188"
          cy="12.5187"
          r="2.92969"
          stroke="white"
          strokeWidth="1.77951"
        />
        <circle cx="12.919" cy="12.516" r="2.08333" fill="black" />
        <line
          x1="9.69995"
          y1="12.2051"
          x2="9.69995"
          y2="20.7426"
          stroke="white"
          strokeWidth="1.875"
        />
      </g>
      <defs>
        <clipPath id="clip0_4222_49165">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2.91895 2.20508)"
          />
        </clipPath>
        <clipPath id="clip1_4222_49165">
          <rect
            width="20.0005"
            height="20.0005"
            fill="white"
            transform="translate(2.91895 2.51758)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
