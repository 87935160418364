/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Fragment } from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import { MdExpandMore } from "react-icons/md"
import SVGInline from "react-svg-inline"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@reach/disclosure"
import { Button, Heading } from "gatsby-interface"
import HtmlContent from "../../components/HtmlContent"
import linkIcon from "!raw-loader!svgo-loader!../../assets/icon-link.svg"

function FaqCard({ children, defaultOpen = false, ...rest }) {
  return (
    <div
      css={theme => ({
        borderBottom: `1px solid ${theme.colors.grey[20]}`,
        position: "relative",
      })}
      {...rest}
    >
      <Disclosure defaultOpen={defaultOpen}>{children}</Disclosure>
    </div>
  )
}

FaqCard.propTypes = {
  children: PropTypes.any,
  defaultOpen: PropTypes.bool,
}

FaqCard.Question = ({ question, displayLink, ...rest }) => {
  return (
    <Fragment>
      <Button
        ButtonComponent={DisclosureButton}
        variant="GHOST"
        css={theme => ({
          width: `100%`,
          justifyContent: `space-between`,
          paddingRight: theme.space[8],
          paddingLeft: theme.space[8],
          paddingTop: theme.space[6],
          paddingBottom: theme.space[6],
          "&:hover:not([disabled]), &:focus:not([disabled]) > svg": {
            animation: `none`,
          },
        })}
      >
        <Heading
          as={`h3`}
          css={theme => ({
            fontSize: theme.fontSizes[3],
            color: theme.colors.grey[90],
            textAlign: `left`,
          })}
          {...rest}
        >
          {question}
        </Heading>
        <MdExpandMore
          css={theme => ({
            fontSize: theme.fontSizes[6],
            transition: `transform 0.3s ease`,
            "[data-state=open] > &": {
              transform: `rotate(-180deg)`,
            },
          })}
        />
      </Button>
      {displayLink && (
        <a
          href={`#faq-${kebabCase(question)}`}
          aria-label={`${question} permalink`}
          css={theme => ({
            position: "absolute",
            top: theme.space[7],
            left: theme.space[4],
          })}
        >
          <SVGInline svg={linkIcon} />
        </a>
      )}
    </Fragment>
  )
}

FaqCard.Answer = ({ answer, ...props }) => (
  <DisclosurePanel
    css={theme => ({
      padding: `${theme.space[8]}`,
      paddingTop: theme.space[5],
    })}
    {...props}
  >
    <HtmlContent
      html={answer}
      css={theme => ({
        color: theme.colors.grey[70],

        "& > *": {
          marginBottom: theme.space[8],

          "&:last-child": {
            marginBottom: 0,
          },
        },
      })}
    />
  </DisclosurePanel>
)

export default FaqCard
