import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { FaqList, Header, Faq, FaqVariant } from "./Faqs.parts"

const PRICING_FALL2022_UTIL_CATEGORY = `PricingFall2022`

export type FaqsProps = {
  variant: FaqVariant
  data: Faq[]
}
export function Faqs({ variant = `main`, data }: FaqsProps) {
  // PricingFall2022 is an util category which let us add new entries without disturbing current Pricing page
  const faqs = data.filter(item =>
    item.category.includes(PRICING_FALL2022_UTIL_CATEGORY)
  )

  // depending on requested component variant, `filteredFaqs` filters out or picks only `Concierge` tagged faqs
  const filteredFaqs = faqs.filter(item => {
    const isConciergeFaq = item.category.includes(`Concierge`)

    if (variant === `concierge`) {
      return isConciergeFaq
    } else {
      return !isConciergeFaq
    }
  })

  if (filteredFaqs?.length === 0) {
    return null
  }

  const faqsByCategory = filteredFaqs.reduce<Record<string, Faq[]>>(
    (acc, cur) => {
      cur.category.forEach(cat => {
        if (cat === PRICING_FALL2022_UTIL_CATEGORY) {
          return
        }

        if (!acc[cat]) {
          acc[cat] = []
        }

        acc[cat].push(cur)
      })

      return acc
    },
    {}
  )

  return (
    <section css={rootCss}>
      <Header variant={variant} />

      {Object.entries(faqsByCategory).map(([cat, faqs]) => {
        return (
          <FaqList
            category={cat}
            faqs={faqs}
            noHeading={variant === `concierge`}
          />
        )
      })}
    </section>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  marginTop: theme.space[15],
  marginBottom: theme.space[8],
})
