import * as React from "react"
import { CheckIcon, ThemeCss, LinkButton, Tooltip } from "gatsby-interface"
import { billing as billingText } from "../../locales/default.js"
import {
  PlanProfile,
  PlanSignup,
  SignupLabel,
} from "gatsby-theme-dashboard/src/modules/billing/shared/constants/planProfiles"
import {
  BillingInterval,
  MachinePricingTier,
} from "gatsby-theme-dashboard/src/modules/graphql/types"
import { DetailsItem } from "./PlansComparison.data"
import { MdInfoOutline } from "react-icons/md"
import * as sanitizeHTML from "sanitize-html"
import { RichValue } from "./PlansComparison.data"
import { useTracker, SegmentEventType } from "@modules/analytics"

type ValueIconProps = {
  value: boolean | RichValue
  isGreyish?: boolean
}

const ValueIcon = ({ value, isGreyish = false }: ValueIconProps) => {
  const isRichValue =
    typeof value === `object` && typeof value.value === `boolean`

  const isTrue = isRichValue ? value.value : value

  return isTrue ? (
    <CheckIcon
      css={(theme) => ({
        color: isGreyish ? theme.colors.grey[50] : theme.colors.purple[50],
        width: theme.space[8],
        height: `auto`,
        marginRight: theme.space[2],
        flexShrink: 0,
      })}
      aria-label="Included"
      size="small"
      aria-hidden="false"
    />
  ) : (
    <span
      css={(theme) => ({
        display: `inline-block`,
        width: `14px`,
        height: `2px`,
        borderRadius: `50%`,
        marginLeft: `8px`,
        backgroundColor: theme.colors.grey[40],
      })}
      aria-label="Not included"
    />
  )
}

export type ColumnHeaderProps = {
  plan: PlanProfile
  billingInterval: BillingInterval
}

export const ColumnHeader = ({ plan, billingInterval }: ColumnHeaderProps) => {
  const { trackSegment } = useTracker()
  const Icon = plan?.icon

  const isEnterprise = plan?.tier === MachinePricingTier.Enterprise
  const isProfessional = plan?.tier === MachinePricingTier.Professional

  const price = billingInterval && plan?.price?.[billingInterval]
  const formatedPrice =
    price && typeof price === "number"
      ? price % 100 === 0 // add decimal if price is not on the dollar
        ? price / 100
        : (price / 100).toFixed(2)
      : 0

  const signupLabel = SignupLabel?.[plan?.signupType]

  const nextStep =
    plan.signupType === PlanSignup.ViaSales
      ? `/contact-sales`
      : `/dashboard/signup?selectedTier=${plan?.tier}&selectedPlan=${plan?.name}`

  const handleClick = () => {
    trackSegment({
      type: SegmentEventType.Track,
      event: `${signupLabel} Clicked`,
      properties: {
        location: "Pricing Page > Plans Comparison",
        plan: plan.name,
      },
    })
  }

  return (
    <th id={`plan-${plan.tier}`} scope="col" css={columnHeaderCss}>
      <span css={planNameCss}>
        {Icon && <Icon />}
        <strong>{plan.name}</strong>
      </span>

      <span css={priceCss}>
        {isEnterprise ? (
          `Custom`
        ) : (
          <React.Fragment>
            <strong>${formatedPrice}</strong>{" "}
            <small> /{billingText.morphemes.month}</small>
          </React.Fragment>
        )}
      </span>

      <LinkButton
        size="S"
        to={nextStep}
        variant={isProfessional ? `PRIMARY` : `SECONDARY`}
        onClick={handleClick}
      >
        {signupLabel}
      </LinkButton>
    </th>
  )
}

/* ColumnHeader styles */

export const columnHeaderCss: ThemeCss = (theme) => ({
  fontSize: theme.fontSizes[3],
  width: `25%`,
  background: theme.colors.white,
  padding: `${theme.space[8]} ${theme.space[6]} ${theme.space[6]}`,
  fontWeight: theme.fontWeights.body,
  zIndex: theme.zIndices.base,
  whiteSpace: `nowrap`,
  borderRadius: `${theme.radii[3]} ${theme.radii[3]} 0 0`,
  verticalAlign: `top`,

  "&:first-child": {
    width: `15%`,
  },

  [theme.mediaQueries.desktop]: {
    top: theme.space[10],
    position: `sticky`,
    minWidth: `13rem`,
    padding: `${theme.space[8]} ${theme.space[9]} ${theme.space[6]}`,
  },
})

const planNameCss: ThemeCss = (theme) => ({
  display: `flex`,
  alignItems: `center`,

  svg: {
    marginRight: theme.space[3],
    width: theme.space[6],
    height: `auto`,
  },
})

const priceCss: ThemeCss = (theme) => ({
  color: theme.colors.grey[50],
  display: `block`,
  marginTop: theme.space[1],
  marginBottom: theme.space[3],

  strong: {
    color: theme.colors.grey[90],
  },

  small: {
    color: theme.colors.grey[60],
    fontSize: theme.fontSizes[0],
  },
})

/* end of ColumnHeader styles */

export type CategoryHeaderProps = {
  category: string
}

export const CategoryHeader = ({ category }: CategoryHeaderProps) => {
  return (
    <tr css={categoryHeaderCss}>
      <th id={category} scope="colgroup">
        {category}
      </th>
      {/* multiplied cells instead of single one with `colSpan={4}` are necessary to highlight the Professional plan column (custom background), see the `tableCss` definition of PlansComparison */}
      <td />
      <td />
      <td colSpan={2} />
    </tr>
  )
}

/* CategoryHeader styles */

const categoryHeaderCss: ThemeCss = (theme) => ({
  fontSize: theme.fontSizes[1],
  whiteSpace: `nowrap`,

  th: {
    padding: 0,
    left: 0,
    position: `sticky`,
    paddingTop: theme.space[7],
    paddingBottom: theme.space[9],
    background: theme.colors.white,
  },
})

/* end of CategoryHeader styles */

export type DataRowProps = {
  data: DetailsItem
  category: string
  plan: MachinePricingTier
}

export const DataRow = ({ data, category, plan }: DataRowProps) => {
  return (
    <tr css={rowCss}>
      <th headers={category} id={data?.id}>
        <span css={cellCss}>
          <span
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(data?.title) }}
          />
          {data.tooltip && (
            <Tooltip label={data.tooltip}>
              <span css={tooltipCss}>
                <MdInfoOutline />
              </span>
            </Tooltip>
          )}
        </span>
      </th>

      {data?.values.map((val, idx) => {
        const isIcon =
          typeof val === `boolean` ||
          (typeof val === `object` && typeof val.value === `boolean`)

        const isString = typeof val === `string`
        const isRichString =
          typeof val === `object` && typeof val.value === `string`

        const hasTooltip = typeof val === `object` && val.tooltip
        const hasComment = typeof val === `object` && val.comment

        return (
          <td key={idx} headers={`plan-${plan} ${category} ${data.id}`}>
            <span css={cellCss}>
              {isIcon && <ValueIcon value={val} />}

              {isString && val}

              {isRichString && val.value}

              {hasTooltip && (
                <Tooltip label={val.tooltip}>
                  <span css={tooltipCss}>
                    <MdInfoOutline />
                  </span>
                </Tooltip>
              )}

              {hasComment && val.comment}
            </span>
          </td>
        )
      })}
    </tr>
  )
}

/* DataRow styles */

const rowCss: ThemeCss = (theme) => ({
  lineHeight: theme.lineHeights.dense,

  th: {
    fontWeight: theme.fontWeights.body,
    padding: theme.space[5],
    paddingLeft: 0,
    paddingRight: theme.space[5],
    fontSize: theme.fontSizes[1],
    background: theme.colors.white,
    color: theme.colors.grey[70],

    "&:first-of-type": {
      position: `sticky`,
      left: 0,
    },
  },

  td: {
    fontSize: theme.fontSizes[1],
    fontWeight: theme.fontWeights.semiBold,
    padding: 0,
    paddingLeft: theme.space[6],
    paddingRight: theme.space[6],
  },

  small: {
    color: theme.colors.grey[50],
  },

  [theme.mediaQueries.desktop]: {
    whiteSpace: `nowrap`,

    td: {
      paddingLeft: theme.space[9],
      paddingRight: theme.space[9],
    },

    th: {
      paddingRight: theme.space[15],
    },
  },
})

const cellCss: ThemeCss = (_theme) => ({
  display: `flex`,
  alignItems: `center`,
})

const tooltipCss: ThemeCss = (theme) => ({
  display: `flex`,
  marginLeft: theme.space[2],
  alignItems: `center`,

  svg: {
    width: theme.space[5],
    height: `auto`,
    color: theme.colors.grey[50],
  },
})
