import * as React from "react"
import { Heading, LinkButton, ThemeCss } from "gatsby-interface"
import stars from "../../assets/ornaments/pricing-page-cta-stars.svg"
import picture from "../../assets/pictures/pricing-page-cta.svg"

export function CtaSection() {
  return (
    <section css={sectionCss}>
      <img src={stars} alt="" css={starsCss} />
      <Heading as="h3" css={headingCss}>
        It’s time your audience receives
        <span>a 5-star web experience.</span>
      </Heading>
      <LinkButton
        size="XL"
        to="/dashboard/signup?selectedPlan=Professional"
        css={btnCss}
      >
        Start free trial
      </LinkButton>
      <img src={picture} alt="" css={pictureCss} />
    </section>
  )
}

/* styles */

const sectionCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[7],
  justifyItems: `center`,
  marginTop: theme.space[15],
  maginBottom: theme.space[3],

  img: {
    margin: 0,
  },
})

const starsCss: ThemeCss = theme => ({
  width: `6rem`,
  height: `auto`,
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[5],
  textAlign: `center`,
  letterSpacing: theme.letterSpacings.tight,

  span: {
    display: `block`,
    color: theme.colors.purple[50],
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[8],
  },
})

const btnCss: ThemeCss = theme => ({
  zIndex: theme.zIndices.base,
})

const pictureCss: ThemeCss = theme => ({
  [theme.mediaQueries.desktop]: {
    justifySelf: `start`,
    transform: `translateY(-3rem)`,
  },
})
