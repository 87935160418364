import * as React from "react"
import { CheckIcon, ThemeCss, LinkButton, Spacer } from "gatsby-interface"
import { interpolateMessage } from "../../locales/utils"
import {
  planInformation as text,
  billing as billingText,
} from "../../locales/default.js"
import {
  TierInformation,
  SupportedTier,
} from "@modules/billing/shared/constants/tiers"
import { Feature } from "./features"
import capitalizeStr from "@modules/toolkit/helpers/capitalizeStr"

/* - - - VALUE ICON - - - - - - - - - - - - - - - - - - - - - - - - */

type ValueIconProps = {
  value: boolean
  color: string
}

const ValueIcon = ({ value, color }: ValueIconProps) => {
  return value ? (
    <CheckIcon
      css={theme => ({
        color: color || theme.colors.green[70],
      })}
      aria-label="Included"
      size="small"
      aria-hidden="false"
    />
  ) : (
    <span
      css={theme => ({
        display: `inline-block`,
        width: `12px`,
        height: `2px`,
        borderRadius: `50%`,
        marginLeft: `4px`,
        backgroundColor: theme.colors.grey[40],
      })}
      aria-label="Not included"
    />
  )
}

/* - - - COLUMN HEADER - - - - - - - - - - - - - - - - - - - - - - - - */

export type ColumnHeaderProps = {
  tier: TierInformation
}

export const ColumnHeader = ({ tier }: ColumnHeaderProps) => {
  const amount = tier?.[`planMonthlyAmount`]?.MONTHLY
  const roundedAmountInUSD =
    typeof amount === `number` ? Math.round(amount / 100) : null
  const Icon: React.ComponentType | null = tier.planIcon || null
  const isEnterprise = tier.key === SupportedTier.Enterprise

  const ctaUrl = isEnterprise
    ? `/contact-sales`
    : `/dashboard/signup?selectedTier=${
        tier.pricingTier
      }&selectedPlan=${capitalizeStr({
        str: tier.pricingTier.toLowerCase(),
      })}`

  return (
    <th
      id={`plan-${tier.key}`}
      scope="col"
      css={theme => [
        columnHeaderCss(theme),
        {
          strong: {
            color: tier.color,
          },
        },
      ]}
    >
      {Icon && <Icon />}

      <strong>{tier.name}</strong>

      <span
        aria-label={interpolateMessage<"amount">(
          text.messages.monthlyAmountAria,
          {
            amount: `${roundedAmountInUSD}`,
          }
        )}
      >
        {amount !== null ? (
          <span>
            $<b>{roundedAmountInUSD}</b>
            <small> / {billingText.morphemes.month}</small>
          </span>
        ) : (
          <span>{text.messages.custom}</span>
        )}
      </span>

      <Spacer size={3} />

      <LinkButton
        size="S"
        to={ctaUrl}
        css={_theme => [
          !isEnterprise && {
            backgroundColor: tier.color,
            borderColor: tier.color,
          },
        ]}
        variant={isEnterprise ? `SECONDARY` : `PRIMARY`}
        tone={isEnterprise ? `NEUTRAL` : `BRAND`}
      >
        {tier.cta}
      </LinkButton>
    </th>
  )
}

export const columnHeaderCss: ThemeCss = theme => ({
  borderBottom: 0,
  verticalAlign: `top`,
  width: `25%`,
  top: theme.space[10],
  position: `sticky`,
  background: `white`,
  padding: `${theme.space[6]} 0`,
  fontWeight: theme.fontWeights.body,
  zIndex: 3,
  fontSize: theme.fontSizes[4],
  whiteSpace: `nowrap`,

  "& > span, & > strong": {
    display: `block`,
    marginBottom: theme.space[1],
  },

  small: {
    color: theme.colors.grey[50],
  },
})

/* - - - CATEGORY HEADER - - - - - - - - - - - - - - - - - - - - - - - - */

export type CategoryHeaderProps = {
  category: string
  categoryIcon?: any
}

export const CategoryHeader = ({
  category,
  categoryIcon = null,
}: CategoryHeaderProps) => {
  const Icon = categoryIcon

  return (
    <tr css={categoryHeaderCss}>
      <th id={category} scope="colgroup">
        <span>
          {categoryIcon && <Icon />}
          {category}
        </span>
      </th>
      <th colSpan={4} />
    </tr>
  )
}

const categoryHeaderCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  whiteSpace: `nowrap`,

  th: {
    border: 0,
    padding: 0,
    left: 0,
    position: `sticky`,

    span: {
      display: `flex`,
      alignItems: `center`,
      paddingTop: theme.space[10],
      paddingBottom: theme.space[5],
      transform: `translateX(-${theme.space[8]})`,
    },

    svg: {
      marginRight: theme.space[3],
    },
  },

  [theme.mediaQueries.hd]: {
    fontSize: theme.fontSizes[3],
  },
})

/* - - - FEATURE ROW - - - - - - - - - - - - - - - - - - - - - - - - */

export type FeatureRowProps = {
  feature: Feature
  category: string
  tiers: any
}

export const FeatureRow = ({ feature, category, tiers }: FeatureRowProps) => {
  const { name, id, values = [] } = feature

  return (
    <tr css={featureRowCss}>
      <th headers={category} id={id}>
        {name}
      </th>
      {values.map((val, idx) => {
        const isBoolean = typeof val === `boolean`

        return (
          <td
            headers={`plan-${tiers[idx].key} ${category ? category : ``} ${id}`}
            key={idx}
          >
            {isBoolean ? (
              <ValueIcon value={val} color={tiers[idx]?.color} />
            ) : (
              val
            )}
          </td>
        )
      })}
    </tr>
  )
}

const featureRowCss: ThemeCss = theme => ({
  lineHeight: theme.lineHeights.dense,

  th: {
    fontWeight: theme.fontWeights.body,
    padding: theme.space[5],
    paddingLeft: 0,
    fontSize: theme.fontSizes[1],
    background: theme.colors.white,
    color: theme.colors.grey[50],

    "&:first-of-type": {
      position: `sticky`,
      left: 0,
    },
  },

  td: {
    fontWeight: theme.fontWeights.semiBold,
    padding: 0,
  },

  small: {
    color: theme.colors.grey[50],
  },

  [theme.mediaQueries.hd]: {
    th: {
      whiteSpace: `nowrap`,
      fontSize: theme.fontSizes[2],
    },
  },
})
